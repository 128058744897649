@tailwind base;
@tailwind components;
@tailwind utilities;

@import "swiper/swiper-bundle.css";
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

body {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  cursor: none;
}

.train-one {
  font-family: "Train One", system-ui;
  font-weight: 400;
  font-style: normal;
}


h1,
h2,
h3,
h4 {
  font-family: "Merriweather", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.merri {
  font-family: "Merriweather", serif;
}

/* HexagonCursor.css */
.hexagon-cursor {
  position: fixed;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 1000;
  transition: transform 0.2s ease, width 0.2s ease, height 0.2s ease;
}

/* Navigation Arrows */
.swiper-button-next,
.swiper-button-prev {
  color: #202020; /* Arrow color */
}

/* Pagination Bullets */
.swiper-pagination-bullet {
  background-color: #f3f3f3; /* Default bullet color */
  opacity: 1; /* Ensures the bullet color is visible */
}

/* Active Pagination Bullet */
.swiper-pagination-bullet-active {
  background-color: #fdc302; /* Active bullet color */
}
